<template>
  <div>
    <details-bar
      type="emergency"
      :permissions="permissions"
    />
    <donation-table filter="emergency" />
  </div>
</template>

<script>
import DetailsBar from "@/app/donations-website/components/DetailsBar";
import DonationTable from "@/app/donations-website/components/DonationsTable";

export default {
  name: "EmergencyDetails",
  components: {
    DetailsBar,
    DonationTable
  },
  data() {
    return {
      permissions: {
        add: "store_emergencies",
        update: "update_emergencies",
        delete: "destory_emergencies"
      }
    };
  },
};
</script>
