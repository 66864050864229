<template>
  <tm-popup
    :submit="onSubmit"
    ref="popup"
    :title="title"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الاسم"
          placeholder="الاسم"
          type="text"
          v-model="campaign.name"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="االوصف"
          placeholder="الوصف"
          type="text"
          v-model="campaign.description"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="statusOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="حالة الحملة"
          v-model="campaign.status"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الموقع"
          placeholder="الموقع"
          type="text"
          v-model="campaign.location"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="عدد المستفيدين"
          placeholder="عدد المستفيدين"
          type="number"
          v-model="campaign.beneficiariesNumber"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="كلفة الفرد"
          placeholder="كلفة الفرد"
          type="number"
          v-model="campaign.costPerUnit"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ الكلي"
          placeholder="المبلغ الكلي"
          type="number"
          v-model="campaign.targetAmount"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ المجموع"
          placeholder="المبلغ المجموع"
          type="number"
          v-model="campaign.collectedAmount"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="ترتيب الحملة في الموقع"
          placeholder="ترتيب الحملة في الموقع"
          type="number"
          v-model="campaign.order"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="تاريخ الانتهاء"
          placeholder="تاريخ الانتهاء"
          type="datetime-local"
          v-model="campaign.finishedAt"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row mi-format-align-justify mb-5">
      <div class="vx-col w-full">
        <h4 class="my-5">
          الصور
        </h4>
        <file-pond-component
          :files="campaign.images.map(image => (image))"
          :multiple="true"
          @fileDeleted="(fileIndex) => fileDeleted(fileIndex)"
          @fileUploaded="(files) => fileUploaded(files)"
          project="websiteDonation"
          ref="fileUpload"
        />
      </div>
    </div>
  </tm-popup>
</template>

<script>
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import TmInput from "@/app/shared/shared-components/TmInput.vue";
import TmSelect from "../../../shared/shared-components/TmSelect";
import TmPopup from "@/app/shared/shared-components/TmPopup.vue";
import utilities from "@/app/shared/utilities";
import toasted from "@/app/shared/utilities/toasted";
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";

const campaignsRepo = RepositoryFactory.get("campaignsRepository");

export default {
  name: "CampaignsPopup",
  components: {
    TmPopup,
    TmInput,
    TmSelect,
    FilePondComponent
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      statusOptions: [
        {
          name: 'مفتوح',
          value: 'OPENED'
        },
        {
          name: 'مغلق',
          value: 'CLOSED'
        }
      ],
    };
  },
  methods: {
    onSubmit() {
      if (utilities.checkTargetAmount(this.campaign.targetAmount, this.campaign.collectedAmount)) {
        this.$refs.popup.endLoading();
        toasted.failed('المبلغ الكلي يجب أن يكون أكبر أو يساوي المبلغ المجموع');
      } else {
        if (this.campaign.id == null)
          this.create();
        else
          this.update();
      }
    },
    initCampaignToRequest(isEdit = false) {
      this.campaign.collectedAmount = parseInt(this.campaign.collectedAmount);
      this.campaign.beneficiariesNumber = parseInt(this.campaign.beneficiariesNumber);
      this.campaign.targetAmount = parseInt(this.campaign.targetAmount);
      this.campaign.order = parseInt(this.campaign.order);
      this.campaign.costPerUnit = parseInt(this.campaign.costPerUnit);
      if (isEdit)
        this.campaign.programId = parseInt(this.$route.params.programId);
      else
        this.campaign.programId = parseInt(this.$route.params.id);
    },
    async create() {
      this.initCampaignToRequest();
      let response = await campaignsRepo.saveCampaign(this.campaign);
      this.$refs.popup.close();
      this.$emit("created", response);
    },
    async update() {
      this.initCampaignToRequest(true);
      let response = await campaignsRepo.updateCampaign(this.campaign);
      this.$refs.popup.close();
      this.$emit("updated", response);
    },
    fileUploaded(files) {
      this.campaign.images.push(...files.map(file => (file)));
    },
    fileDeleted(imageIndex) {
      this.campaign.images.splice(imageIndex, 1);
    },
    open() {
      if (this.campaign.id != null)
        this.campaign.finishedAt = this.campaign.finishedAt.split('.')[0];
      this.$refs.popup.open();
    },
  },
};
</script>

<style scoped>

</style>

