<template>
  <div>
    <vs-row vs-type="flex">
      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-align="right"
        vs-w="6"
        class="mb-3"
      >
        <h4 class="mt-3 mr-3">
          اسم {{ recordName }}:
        </h4>
        <vs-card
          style="background-color: white; width:250px; height: 40px; border-radius:7px"
        >
          <vs-row>
            <h5 style="margin-left: auto; margin-right: auto">
              {{ record.name }}
            </h5>
          </vs-row>
        </vs-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="end"
        vs-align="left"
        vs-w="6"
      >
        <h4 class="mt-3 mr-3">
          {{ title }} :
        </h4>
        <vs-card style="background-color: white; height: 40px; width:200px; border-radius:7px">
          <vs-row>
            <div>
              <feather-icon
                icon="EyeIcon"
                style="color:#3A273E"
                @click="popupActiveDetails = true"
                class="ml-2 mr-5 text-success button_icon pointer"
              />
            </div>
            <div v-can="permissions.update">
              <vs-switch
                color="success"
                @input="closeRecord"
                v-model="checked"
                class="mr-2 pointer"
              />
            </div>
            <div v-can="permissions.update">
              <feather-icon
                icon="EditIcon"
                style="color:#3A273E"
                @click="editRecord"
                class="ml-2 mr-5 button_icon pointer"
              />
            </div>
            <div v-can="permissions.delete">
              <feather-icon
                icon="Trash2Icon"
                svg-classes="text-danger"
                @click="confirmDeleteRecord"
                class=" mr-2 button_icon pointer"
              />
            </div>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
      >
        <vs-card
          class="mr-3"
          style="background-color: white; height: 40px; width:190px; border-radius:7px"
        >
          <vs-row>
            <div style="font-size: 12px; font-weight: 700">
              نسبة الاكتمال :
            </div>
            <radial-progress-bar
              :diameter="36"
              :completed-steps="record.completePercentage"
              :total-steps="100"
              inner-stroke-color="#E6E9F0"
              :inner-stroke-width="2"
              :stroke-width="2"
              start-color="#48E18A"
              stop-color="#27B47B"
              style="bottom:10px; margin-right: 40px"
              class="mt-1"
            >
              <p>
                {{ record.completePercentage }}%
              </p>
            </radial-progress-bar>
          </vs-row>
        </vs-card>
        <vs-card
          class="mr-3"
          style="background-color: white; height: 40px; width:190px; border-radius:7px"
        >
          <vs-row>
            <img src="@//assets/images/coinIcon.png">
            <div
              style="font-size: 12px; font-weight:700"
              class="ml-2"
            >
              المبلغ الكلي :
              <b>{{ record.targetAmount }}</b>
            </div>
          </vs-row>
        </vs-card>
        <vs-card
          class="mr-3"
          style="background-color: white; height: 40px; width:190px; border-radius:7px"
        >
          <vs-row>
            <img src="@//assets/images/goldCoinIcon.png">
            <div
              style="font-size: 12px; font-weight: 700"
              class="ml-2"
            >
              المبلغ المتبقي :
              <b style="color:#D89814">{{ record.remainingAmount }}</b>
            </div>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>
    <update-campaign
      :campaign="record"
      title="تعديل حملة"
      @updated="recordUpdated"
      ref="updateCampaignPopupRef"
    />
    <update-emergency
      :emergency="record"
      title="تعديل حالة"
      ref="updateEmergencyPopupRef"
      @updated="recordUpdated"
    />
    <vs-popup
      :title="detailsTitle"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div
        v-for="(image,index) in record.images"
        :key="index"
      >
        <img
          :src="image"
          alt="content-img"
          class="responsive rounded-lg mb-3"
          style="height: 20em;object-fit: cover"
        >
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 px-4 mt-2">
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.name"
          />
        </div>

        <div>
          <label>الحالة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.status"
          />
        </div>
        <div>
          <label>الموقع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.location"
          />
        </div>
        <div v-if="record.beneficiariesNumber">
          <label>عدد المستفيدين :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.beneficiariesNumber"
          />
        </div>
        <div>
          <label>المبلغ الكلي :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.targetAmount"
          />
        </div>
        <div>
          <label>المبلغ المجموع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.collectedAmount"
          />
        </div>
        <div>
          <label>المبلغ المتبقي :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.remainingAmount"
          />
        </div>
        <div>
          <label>نسبة الاكتمال :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.completePercentage"
          />
        </div>
        <div>
          <label>الترتيب في الموقع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.order"
          />
        </div>
        <div>
          <label>تاريخ الانتهاء :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.finishedAt"
          />
        </div>
        <div v-if="record.program">
          <label>البرنامج :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.program.name"
          />
        </div>
        <div v-if="record.programs">
          <label>البرنامج :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.programs.name"
          />
        </div>
        <div>
          <label> تاريخ الإضافة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.createdAt"
          />
        </div>
        <div>
          <label> تاريخ التعديل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.updatedAt"
          />
        </div>
        <div v-if="record.family_name">
          <label>اسم الأسرة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.family_name"
          />
        </div>
        <div v-if="record.family_number">
          <label>رقم الأسرة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.family_number"
          />
        </div>
        <div
          class="col-span-2"
        >
          <label>الوصف :</label>
          <vs-textarea
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="record.description"
          />
        </div>
      </div>

      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import {RepositoryFactory} from "../donations-repositories/donationsRepositoryFactory";
import updateCampaign from "../views/campaigns/campaignsPopup";
import updateEmergency from "../views/emergencies/emergencyPopup";

const campaignsRepo = RepositoryFactory.get("campaignsRepository");
const emergencyRepo = RepositoryFactory.get("emergenciesRepository");

export default {
  name: "DetailsBar",
  components: {
    RadialProgressBar,
    updateCampaign,
    updateEmergency
  },
  props: {
    type: {type: String, required: true},
    permissions: {type: Object, required: true}
  },
  data() {
    return {
      record: {},
      checked: true,
      title: '',
      detailsTitle: '',
      popupActiveDetails: false,
      recordName: ''
    };
  },
  async created() {

    if (this.type === 'campaign') {
      this.record = await campaignsRepo.getCampaignById(this.$route.params.id);
      this.title = "خيارات الحملة";
      this.detailsTitle = "تفاصيل الحملة";
      this.recordName = "الحملة";
    } else {
      this.record = await emergencyRepo.getEmergencyById(this.$route.params.id);
      this.title = "خيارات الحالة";
      this.detailsTitle = "تفاصيل الحالة";
      this.recordName = "الحالة";
    }
    this.checked = this.record.status === 'OPENED';
  },
  methods: {
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'هل أنت متأكد من الحذف؟',
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteRecord,
        acceptText: 'موافق'
      });
    },
    async recordUpdated() {
      if (this.type === 'campaign')
        this.record = await campaignsRepo.getCampaignById(this.$route.params.id);
      else
        this.record = await emergencyRepo.getEmergencyById(this.$route.params.id);
      this.checked = this.record.status === 'OPENED';
    },
    editRecord() {
      if (this.type === 'campaign')
        this.$refs.updateCampaignPopupRef.open();
      else
        this.$refs.updateEmergencyPopupRef.open();
    },
    deleteRecord() {
      if (this.type === 'campaign') {
        campaignsRepo.deleteCampaign(this.$route.params.id);
        this.$router.push({path: `/donations/programDetails/${this.record.programId}/campaigns`});
      } else {
        emergencyRepo.deleteEmergency(this.$route.params.id);
        this.$router.push({path: `/donations/programDetails/${this.record.programId}/emergencies`});
      }
    },
    closeRecord() {
      if (this.checked)
        this.record.status = 'OPENED';
      else
        this.record.status = 'CLOSED';
      if (this.type === 'campaign')
        campaignsRepo.updateCampaign(this.record);
      else
        emergencyRepo.updateEmergency(this.record);
    },
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.vs-textarea {
  height: 100px
}
</style>
