<template>
  <tm-popup
    :submit="onSubmit"
    ref="popup"
    :title="title"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الاسم"
          placeholder="الاسم"
          type="text"
          v-model="emergency.name"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الوصف"
          placeholder="الوصف"
          type="text"
          v-model="emergency.description"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="statusOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="الحالة"
          v-model="emergency.status"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الموقع"
          placeholder="الموقع"
          type="text"
          v-model="emergency.location"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ الكلي"
          placeholder="المبلغ الكلي"
          type="number"
          v-model="emergency.targetAmount"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ المجموع"
          placeholder="المبلغ المجموع"
          type="number"
          v-model="emergency.collectedAmount"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="ترتيب الحالة في الموقع"
          placeholder="ترتيب الحالة في الموقع"
          type="number"
          v-model="emergency.order"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="تاريخ الانتهاء"
          placeholder="تاريخ الانتهاء"
          type="datetime-local"
          v-model="emergency.finishedAt"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="اسم الأسرة"
          placeholder="اسم الأسرة"
          type="text"
          v-model="emergency.family_name"
          validation-rules=""
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="رقم الأسرة"
          placeholder="رقم الأسرة"
          type="number"
          v-model="emergency.family_number"
          validation-rules=""
        />
      </div>
    </div>
    <div class="vx-row mi-format-align-justify mb-5">
      <div class="vx-col w-full">
        <h4 class="my-5">
          الصور
        </h4>
        <file-pond-component
          :files="emergency.images.map(image => (image))"
          :multiple="true"
          @fileDeleted="(fileIndex) => fileDeleted(fileIndex)"
          @fileUploaded="(files) => fileUploaded(files)"
          project="websiteDonation"
          ref="fileUpload"
        />
      </div>
    </div>
  </tm-popup>
</template>

<script>
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import TmInput from "@/app/shared/shared-components/TmInput.vue";
import TmSelect from "../../../shared/shared-components/TmSelect";
import TmPopup from "@/app/shared/shared-components/TmPopup.vue";
import utilities from "@/app/shared/utilities";
import toasted from "@/app/shared/utilities/toasted";
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";

const emergencyRepo = RepositoryFactory.get("emergenciesRepository");

export default {
  name: "EmergencyPopup",
  components: {
    TmPopup,
    TmInput,
    TmSelect,
    FilePondComponent
  },
  props: {
    emergency: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      statusOptions: [
        {
          name: 'مفتوح',
          value: 'OPENED'
        },
        {
          name: 'مغلق',
          value: 'CLOSED'
        }
      ],
    };
  },
  methods: {
    onSubmit() {
      if (utilities.checkTargetAmount(this.emergency.targetAmount, this.emergency.collectedAmount)) {
        this.$refs.popup.endLoading();
        toasted.failed('المبلغ الكلي يجب أن يكون أكبر أو يساوي المبلغ المجموع');
      } else {
        if (this.emergency.id == null)
          this.create();
        else
          this.update();
      }
    },
    initEmergencyToRequest(isEdit = false) {
      this.emergency.collectedAmount = parseInt(this.emergency.collectedAmount);
      this.emergency.targetAmount = parseInt(this.emergency.targetAmount);
      this.emergency.order = parseInt(this.emergency.order);
      if (isEdit)
        this.emergency.programId = parseInt(this.$route.params.programId);
      else
        this.emergency.programId = parseInt(this.$route.params.id);
    },
    async create() {
      this.initEmergencyToRequest();
      let response = await emergencyRepo.saveEmergency(this.emergency);
      this.$refs.popup.close();
      this.$emit("created", response);
    },
    async update() {
      this.initEmergencyToRequest(true);
      let response = await emergencyRepo.updateEmergency(this.emergency);
      this.$refs.popup.close();
      this.$emit("updated", response);
    },
    fileUploaded(files) {
      this.emergency.images.push(...files.map(file => (file)));
    },
    fileDeleted(imageIndex) {
      this.emergency.images.splice(imageIndex, 1);
    },
    open() {
      if (this.emergency.id != null)
        this.emergency.finishedAt = this.emergency.finishedAt.split('.')[0];
      this.$refs.popup.open();
    },
  },
};
</script>

<style scoped>

</style>

